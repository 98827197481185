<template>
  <div class="lg:flex lg:justify-center">
    <div class="lg:w-1/2">

      <div class="mt-8"></div>

      <LoginForm @onLoginSuccess="onLoginSuccess">
        <h1 class="text-4xl my-4">
          vem pra pixta 🕺🏾
        </h1>
        <p class="my-2 font-medium">
          Chegando agora ou já tem cadastro?
        </p>
        <p class="my-2 font-medium">
          Informe seu email e vamos começar ✨
        </p>
      </LoginForm>
    </div>
    <!-- v-if="!isLoggedIn"  -->

  </div>
</template>

<script setup>
import { useUserStore } from '@/store/user.js'
import { useMainStore } from '@/store/root.js'

import { storeToRefs } from 'pinia'

const userStore = useUserStore()
const mainStore = useMainStore()
const router = useRouter()
const route = useRoute()

const { loggedIn: isLoggedIn, redirectAfterLogin } = storeToRefs(userStore)
const { saveRedirectAfterLogin } = userStore

if(route.query.redirect) {
  // redirectAfterLogin.value = route.query.redirect
  saveRedirectAfterLogin(route.query.redirect)
}


const onLoginSuccess = async () => {
  if (userStore.redirectAfterLogin) {
    const pathTo = userStore.redirectAfterLogin
    userStore.clearRedirectAfterLogin()

    console.log('redirecting to', pathTo)

    await router.push(pathTo)
  } else {
    await router.push('/')
  }
}
</script>
