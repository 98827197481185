<template>
  <div class="px-4">
    <!--
      WHERE SHOULD WE SEND YOUR TICKETS?
      New to Shotgun or coming back, enter your email to continue: -->

      <transition
        enter-active-class="duration-500 ease-out"
        enter-from-class="transform opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-250 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="transform opacity-0"
      >
      <form v-if="!isEmailSent && !isSignupForm" ref="emailForm" @submit.stop.prevent="onSubmit" id="login-form" action="/entrar" method="POST" autocomplete="on">
        <slot>
          <p class="my-2">
            Para continuar com seu pedido, informe seu email 💃
            <!-- Mesmo que não tenha cadastro, informe o email e vamos começar ✨ -->
          </p>
        </slot>

        <div class="my-4">
          <label class="mb-1 block" for="email">Email</label>
          <input class="outline-0 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded block w-full p-2 dark:bg-zinc-800 dark:border-zinc-700 dark:placeholder-gray-400 dark:text-white" type="text" name="email" minlength="5" maxlength="100" v-model="form.email" required autocomplete="email" id="email">
        </div>

        <!-- <input type="password" style="display: none;"> -->
        <button v-if="!isSignupForm" :disabled="!isFormValid || isFormLoading" class="border enabled:border-2 bg-black text-white enabled:hover:bg-zinc-100 enabled:hover:text-black dark:bg-gray-100 dark:text-black enabled:dark:hover:bg-zinc-800 enabled:dark:hover:text-white enabled:cursor-pointer transition rounded p-2 w-1/2 disabled:opacity-30 enabled:border-gray-500 enabled:font-medium w-full">
          Continuar
        </button>
      </form>

      <form v-else-if="isSignupForm" ref="signupForm" @submit.stop.prevent="onSubmitSignup" id="login-form" action="/entrar" method="POST" autocomplete="on">
        <p class="mt-2 mb-0">
          primeira vez na pixta? 🕺🏾
        </p>
        <p class="mb-4 text-sm">
          precisamos de algumas infos
        </p>

        <div :class="['my-4', { 'input-error': formErrors.name }]">
          <label class="block mb-1">Seu nome completo</label>
          <input class="outline-0 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded block w-full p-2 dark:bg-zinc-800 dark:border-zinc-700 dark:placeholder-gray-400 dark:text-white" type="text" required v-model="form.name" name="name" autocomplete="name" id="name" />
          <p v-if="formErrors.name" class="transition transform text-sm text-red-700">{{ formErrors.name }}</p>
        </div>

        <div :class="['my-4', { 'input-error': formErrors.name }]">
          <label class="block mb-1">Celular/Whatsapp</label>
          <input class="outline-0 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded block w-full p-2 dark:bg-zinc-800 dark:border-zinc-700 dark:placeholder-gray-400 dark:text-white" name="phone" id="phone" autocomplete="tel-national" type="text" maxlength="16" minlength="14" inputmode="tel" v-model="form.cellphone" required v-maska data-maska="['(##) ####-####', '(##) #####-####']" placeholder="(__) _____-____">
          <p v-if="formErrors.cellphone" class="transition transform text-sm text-red-700">{{ formErrors.cellphone }}</p>
        </div>

        <div :class="['my-4', { 'input-error': formErrors.document }]">
          <label class="block mb-1">CPF</label>
          <input class="outline-0 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded block w-full p-2 dark:bg-zinc-800 dark:border-zinc-700 dark:placeholder-gray-400 dark:text-white" type="text" minlength="11" maxlength="20" v-model="form.document" required autocomplete="document" name="document" v-maska data-maska="['###.###.###-##', '##.###.###/####-##']">
          <p v-if="formErrors.document" class="transition transform text-sm text-red-700">{{ formErrors.document }}</p>
        </div>

        <div :class="['my-4', { 'input-error': formErrors.birthdate }]">
          <label class="block mb-1">Data de Nascimento</label>
          <!-- <input class="outline-0 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded block w-full p-2 dark:bg-zinc-800 dark:border-zinc-700 dark:placeholder-gray-400 dark:text-white" type="date" v-model="form.birthdate" required autocomplete="birthdate" name="birthdate" /> -->
          <vue-date-picker v-model="form.birthdate" :reverse-years="true" :year-range="[1920, 2010]" :clearable="false" required utc="preserve" :text-input-options="{ format: 'dd/MM/yyyy'}" format="dd/MM/yyyy" :flow="['year', 'month', 'calendar']" :enable-time-picker="false" :auto-apply="true" :dark="true" locale="pt-BR" autocomplete="birthdate"></vue-date-picker>
          <p v-if="formErrors.birthdate" class="transition transform text-sm text-red-700">{{ formErrors.birthdate }}</p>
        </div>

        <div class="mt-2">
          <button :disabled="isFormLoading" class="border enabled:border-2 bg-black text-white enabled:hover:bg-zinc-100 enabled:hover:text-black dark:bg-gray-100 dark:text-black enabled:dark:hover:bg-zinc-800 enabled:dark:hover:text-white enabled:cursor-pointer transition rounded p-2 w-1/2 disabled:opacity-30 enabled:border-gray-500 enabled:font-medium w-full">
            Continuar
          </button>
        </div>
      </form>

      <OtpForm v-else-if="isWaitingOtp" :email="form.email" @onOtpLogin="onOtpLogin" @onOtpSuccess="onOtpSuccess"></OtpForm>
    </transition>
  </div>
</template>

<script>
import UserService from '@/services/user.service.js'
import ButtonSubmit from '@/components/ButtonSubmit.vue'
import OtpForm from '@/components/OtpForm.vue'
import { toast } from 'vue3-toastify';

import { mapStores } from 'pinia'
import { useUserStore } from '@/store/user.js'
import { useMainStore } from '@/store/root.js'
export default {
  name: 'LoginForm',
  components: {
    ButtonSubmit,
    OtpForm
  },
  computed: {
    ...mapStores(useUserStore, useMainStore)
  },
  data () {
    return {
      form: {
        email: '',
        cellphone: '',
        name: ''
      },
      otpForm: {
        login_code: ''
      },
      isWaitingOtp: false,
      isEmailSent: false,
      isFormLoading: false,
      isFormOtpLoading: false,
      isFormValid: false,
      isFormOtpValid: false,
      isSignupForm: false,
      isSignupFormValid: false,
      formErrors: {
        email: '',
        cellphone: '',
        name: '',
        document: '',
        birthdate: ''
      }
    }
  },
  mounted () {
  },
  emits: ['onLoginSuccess'],
  watch: {
    form: {
      deep: true,
      handler: function () {
        this.validateForm()
      }
    },
    otpForm: {
      deep: true,
      handler: function () {
        this.validateOtpForm()
      }
    },
    'form.cellphone': function (newVal) {
      // this.validateForm()
      // +5541921929129
      // fixing +55 autofill
      const cleanPhone = newVal.replace(/\D/g, '')
      if(cleanPhone.length == 13 && cleanPhone.startsWith('55')) {
        this.form.cellphone = cleanPhone.slice(2)
      }
    },
  },
  methods: {
    validateForm () {
      try {
        this.isFormValid = this.$refs.emailForm.checkValidity()
      } catch (_) {
        this.isFormValid = true // pra nao dar merda
      }
    },
    validateOtpForm () {
      try {
        this.isFormOtpValid = this.$refs.otpForm.checkValidity()
      } catch (_) {
        this.isFormOtpValid = true // pra nao dar merda
      }
    },
    async onSubmit () {
      try {
        this.isFormLoading = true
        if (this.isSignupForm) {
          return
        }

        this.form.email = this.form.email.toLowerCase().trim()

        const response = await UserService.login(this.form)

        this.isWaitingOtp = true
        this.isEmailSent = true

        // if(response.data.email_sent) {
        //   toast('confira sua caixa de entrada', { type: 'success' })
        // }
      } catch (err) {
        // console.log('err login', err)
        this.isFormLoading = false
        if(err.response.status == 404) {
          this.isSignupForm = true
          return;
        }

        if(err && err.code == 'ERR_NETWORK') {
          toast('erro ao entrar - verifique sua internet', { type: 'error' })
        }

        if(err.response && err.response.data) {
          toast(JSON.stringify(err.response.data), { type: 'error' })
        }
      }
    },
    async clearErrors () {
      this.formErrors = {
        email: '',
        cellphone: '',
        name: '',
        document: '',
        birthdate: ''
      }
    },

    async onSubmitSignup () {
      try {

        this.clearErrors()
        this.isFormLoading = true
        this.form.email = this.form.email.toLowerCase().trim()

        // const response =
        await UserService.register(this.form)

        this.isSignupForm = false
        this.onSubmit()
      } catch (err) {
        this.isFormLoading = false
        this.isSignupForm = true

        if(!err.response || !err.response.data) {
          toast('rolou um erro ao se cadastrar', { type: 'error' })
          return
        }

        if(err.response.data.name) {
          this.formErrors.name = err.response.data.name[0]
        }

        if(err.response.data.email) {
          this.formErrors.email = err.response.data.email[0]
        }

        if(err.response.data.cellphone) {
          this.formErrors.cellphone = err.response.data.cellphone[0]
        }
        if(err.response.data.document) {
          this.formErrors.document = err.response.data.document[0]
        }
        if(err.response.data.birthdate) {
          this.formErrors.birthdate = err.response.data.birthdate[0]
        }


      }
    },

    async onOtpLogin (val) {
      // console.log('teste')
      // alert(val)
    },

    async onOtpSuccess (val) {
      // console.log('onOtpSuccess')
      // this.onSubmit()
      this.isWaitingOtp = true
      this.isEmailSent = true

      this.$emit('onLoginSuccess')

      // if (this.userStore.redirectAfterLogin) {
      //   const pathTo = this.userStore.redirectAfterLogin
      //   this.userStore.clearRedirectAfterLogin()

      //   console.log('redirecting to', pathTo)

      //   return this.$router.push(pathTo)
      // } else {
      //   return this.$router.push('/')
      // }
    }
  }
}
</script>
